import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import AppLayout from "./AppLayout";
import Page404 from "./Page404";
import ContentLoader from "../components/Loader/ContentLoader";

// Lazy-loaded components
const DreamsMain = lazy(() => import("../containers/Goals/DreamsMain"));
const PreLogin = lazy(() => import("../containers/PreLogin/PreLogin"));
const Login = lazy(() => import("../containers/Login/Login"));
const Home = lazy(() => import("../containers/DashBoard/Home"));
const SignUp = lazy(() => import("../containers/Login/SignUp"));
const OnBoardingStep = lazy(() => import("../containers/NewOnBoardingFlow/OnBoardingMain"));

const Routing = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="text-center">
            <ContentLoader />
          </div>
        }
      >
        <AppLayout>
          <Switch>
            <Route exact path="/" component={PreLogin} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Home} />
            <Route path="/Open-Account" component={SignUp} />
            <Route path="/onboarding" component={OnBoardingStep} />
            {/* Add other routes as needed */}
            
            {/* <Route path="/dreams/main/*" component={DreamsMain} /> */}

            {/* 404 Page */}
            <Route path="/404" component={Page404} />
            {/* Catch-all route for 404 */}
            <Route component={Page404} />
          </Switch>
        </AppLayout>
      </Suspense>
    </Router>
  );
};

export default Routing;
