import React from 'react';
//import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import './assets/scss/global.scss';
import './assets/scss/global1.scss';
import './index.css';
import "c3/c3.css";

import "react-datepicker/dist/react-datepicker.css";
//import App from './App';
import reportWebVitals from './reportWebVitals';
 import Routes from "./routes/Routing";
const AppBundle = <Routes />

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
ReactDOM.render(AppBundle, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
